import { useState, useCallback } from "react";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { object, string } from "yup";

import strings from "../localization";

const inputFields = [
  {
    id: "username",
    label: strings.contactFormLabels.username,
    placeholder: strings.contactFormLabels.username,
  },
  {
    id: "company",
    label: strings.contactFormLabels.companyName,
    placeholder: strings.contactFormLabels.companyName,
  },
  {
    id: "city",
    label: strings.contactFormLabels.city,
    placeholder: strings.contactFormLabels.city,
  },
  {
    id: "email",
    label: strings.contactFormLabels.email,
    placeholder: strings.contactFormLabels.email,
  },
  {
    id: "phone",
    label: strings.contactFormLabels.phone,
    placeholder: "(+99) 999-9999-9999",
  },
];

const contactFormSchema = object().shape({
  username: string().required({ message: strings.validationErrors.required }),
  company: string({ message: strings.validationErrors.required }),
  city: string().required({ message: strings.validationErrors.required }),
  email: string()
    .email({ message: strings.validationErrors.invalidFormat })
    .required({ message: strings.validationErrors.required }),
  phone: string().required({ message: strings.validationErrors.required }),
});

function ContactInputField({
  label,
  id,
  placeholder,
  error,
  onChange,
  keyfilter = /^[^<>*!]+$/, // Block code injecting symbols: < > * !
}) {
  return (
    <div id={id} className="flex flex-column gap-3">
      <label>{label}</label>
      <InputText
        placeholder={placeholder}
        label={label}
        onChange={(e) => onChange(e.target.value)}
        invalid={error}
        keyfilter={keyfilter}
      />
      <small style={{ width: 300 }} className="text-red-500">
        {error}
      </small>
    </div>
  );
}

function ContactUs({ stepperRef, submitted, setSubmitted }) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const submitForm = useCallback(async () => {
    try {
      await contactFormSchema.validate(values, {
        abortEarly: false,
      });
   const hostingUrl = window.location.href;

      await fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/143561006/560207fa-834b-419b-8406-13ab0eec25a3",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: [
              ...Object.keys(values).map((key) => ({
                name: key,
                value: values[key],
              })),
              {
                name: "page_url", // Add hosting URL as a separate field
                value: hostingUrl,
              },
            ],
          }),
        }
      );

      setSubmitted(true);
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds 
      window.location.href = "https://cocharge.de/cocharge-connect"; // Redirect to the given URL

    } catch (error) {
      const errors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          const msgText = err.errors[0].message;
          const fieldName = err.path;

          errors[fieldName] = msgText;
        });
      }
      setErrors(errors);
    }
  }, [values, setSubmitted, setErrors]);

  return (
    <section className="contact-form">
      {!submitted && (
        <div className="flex flex-column min-w-full">
          <div className="flex flex-column gap-3">
            {inputFields.map(
              ({ id, label, placeholder, keyfilter = undefined }) => (
                <ContactInputField
                  key={id}
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  onChange={(value) =>
                    setValues((prev) => ({ ...prev, [id]: value }))
                  }
                  error={errors[id]}
                  keyfilter={keyfilter}
                />
              )
            )}
          </div>
        </div>
      )}

      {submitted && (
        <div className="flex flex-column w-full align-items-center justify-center">
          <h2 id="submissionTitle" >{strings.contactSubmissionTitle}</h2>
          <p>{strings.contactSubmissionSubtitle}</p>
        </div>
      )}

      <div className="flex align-items-center mt-7 justify-content-between">
        <Button
          className="mr-8"
          label={strings.pointers.back}
          severity="secondary"
          icon="pi pi-arrow-left"
          onClick={() => stepperRef.current.prevCallback()}
        />
        {!submitted && (
          <Button
            className="ml-8"
            label={strings.pointers.submit}
            icon="pi pi-arrow-right"
            iconPos="right"
            onClick={(e) => {
              e.preventDefault();
              submitForm();
            }}
          />
        )}
      </div>
    </section>
  );
}

export default ContactUs;
